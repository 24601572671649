import React, { useEffect, useState } from "react";
import "../App.css";
import Play from "../assets/play.png";
import Pause from "../assets/pause.png";
// import WaveSurfer from "wavesurfer.js";
import WaveSurfer from "wavesurfer.js";
import { useNavigate } from "react-router-dom";
import { S3, GetObjectCommand } from "@aws-sdk/client-s3";

const s3Client = new S3({
  forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  endpoint: "https://nyc3.digitaloceanspaces.com",
  region: "us-east-1",
  credentials: {
    accessKeyId: "DO00BDB9MDHYVCQXK4RW",
    secretAccessKey: "at3hsgRuU3tSRXqGjG0ApMLAQ9yhqA22a0QTkosHfrY",
  },
});

export default function RadioWave(props) {
  const [speed, setSpeed] = useState(1);
  const [file, setFile] = useState(null);
  const [playPause, setPlayPause] = useState(false);
  const [current, setCurrent] = useState(null);

  const navigate = useNavigate();
  const getObj = async () => {
    const command = new GetObjectCommand({
      Bucket: "play-along-tracks",
      Key: props.tuneFile
        ? props.tuneFile
        : "lesson1/04 Boil The Cabbage Fast - Guitar.mp3",
    });
    try {
      const response = await s3Client.send(command);
      // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
      const test = await response.Body.transformToString("base64");
      setFile(test);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const val = getObj();
  }, [props.tuneFile]);

  const [isPlayingLocal, setIsPlayingLocal] = useState(props.isPlaying);

  useEffect(() => {
    let preservePitch = true;
    const speeds = [0.25, 0.5, 1, 2, 4];

    var element = document.getElementById("Wave");
    if (element.firstChild) {
      props.setIsPlaying(false);
      element.firstChild.remove();
      // element.parentNode.removeChild(element);
      var old_element = document.getElementsByClassName("play-pause")[0];
      var new_element = old_element.cloneNode(true);
      new_element.addEventListener("click", () => {
        // console.log("attempting??", props.isPlaying, props.setIsPlaying, props.);
      });
      old_element.parentNode.replaceChild(new_element, old_element);
      var old_speed = document.getElementById("speed");
      var new_speed = old_speed.cloneNode(true);
      old_speed.parentNode.replaceChild(new_speed, old_speed);
    }

    if (file != null) {
      const wavesurfer = WaveSurfer.create({
        container: "#Wave",
        height: 50,
        width: 100,
        waveColor: "#7b2d28",
        progressColor: "#c0c0c0",
        audioRate: 1, // set the initial playback rate
      });

      wavesurfer
        // .loadBlob("data:audio/wav;base64," + file)
        .load("data:audio/wav;base64," + file)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
      wavesurfer.on("click", () => {
        wavesurfer.play();
      });

      // Set the playback rate
      document
        .querySelector('input[type="number"')
        .addEventListener("input", (e) => {
          const speed = e.target.value;
          console.log(e.target.valueAsNumber, parseInt(speed).toFixed(2));
          document.querySelector("#rate").textContent =
            parseInt(speed).toFixed(2);
          wavesurfer.setPlaybackRate(speed, preservePitch);
          setSpeed(speed);
          wavesurfer.play();
        });

      //   // Play/pause
      // document.querySelector("button").addEventListener("click", () => {
      //   wavesurfer.playPause();
      // });

      document
        .getElementsByClassName("play-pause")[0]
        .addEventListener("click", () => {
          console.log("here", props.isPlaying, isPlayingLocal);
          // props.setIsPlaying(!isPlayingLocal);
          // setIsPlayingLocal(!isPlayingLocal);
          props.onPlayPause();
          wavesurfer.playPause();
        });
      console.log("we get here? ");
      // // Cleanup WaveSurfer instance on component unmount
      // return () => {
      //   wavesurfer.current.destroy();
      // };

      const handleFinish = () => {
        props.setIsPlaying(false); // Reset play state when track ends
      };
      return () => wavesurfer.un("finish", handleFinish);
    }
  }, [file]);

  return (
    <>
      <div
        style={{
          width: 100,
          height: 150,
        }}
      >
        <div
          style={{
            marginTop: props.height == "small" ? 25 : 50,
            height: props.height == "small" ? 400 : 400,
          }}
          id="Wave"
        />
        <div>
          <div
            style={{
              display: "flex",
              margin: "1rem 0",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => {
                setPlayPause(!playPause);
              }}
              style={{
                // backgroundColor: "black",
                // color: "white",
                // width: 100,
                // height: 50,
                borderRadius: 4,
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
                backgroundColor: "#f8f6f1",
              }}
            >
              <div id="play">
                {playPause ? (
                  <img src={Pause} style={{ width: 25 }} />
                ) : (
                  <img src={Play} style={{ width: 25 }} />
                )}
              </div>
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ fontSize: 16 }}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    paddingBottom: 10,
                  }}
                >
                  Change Playback Rate
                </div>
                <input
                  // onChange={(event) => {
                  //   setSpeed(Number(event.target.value));
                  // }}

                  value={speed}
                  id="speed"
                  type="number"
                  min="0"
                  max="2"
                  style={{
                    width: 150,
                    height: 26,
                    borderRadius: 8,
                    borderWidth: 1,
                    textAlign: "center",
                    accentColor: "#7b2d28",
                  }}
                />{" "}
              </label>
              <label style={{ fontSize: 12, marginTop: 8 }}>
                Playback rate:{" "}
                <span id="rate" style={{ fontWeight: "bold" }}>
                  1.00
                </span>
                <span style={{ fontWeight: "bold" }}>x</span>
              </label>
            </div>

            {/* <label>
              <input type="checkbox" checked />
              Preserve pitch
            </label> */}
            {/* <audio controls src={"data:audio/mpeg;base64" + file} /> */}
          </div>{" "}
        </div>
      </div>
    </>
  );
}
