import React, { useState, useEffect } from "react";
import "@stripe/stripe-js";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import LogInModal from "./components/LogInModal";
import Course from "./components/Course";
import Header from "./components/Header";
import Splash from "./routes/Splash";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import ErrorPage from "./routes/ErrorPage";
import Home from "./routes/Home";
import Profile from "./routes/Profile";
import Radio from "./routes/Radio";
import Progress from "./routes/Progress";
import Store from "./routes/Store";
import Offers from "./routes/Offers";
import Circle from "./routes/Circle";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAM-2T0bno25jigtMP4JWXd7y71nmXQzso",
  authDomain: "fiddleschool-6fa40.firebaseapp.com",
  projectId: "fiddleschool-6fa40",
  storageBucket: "fiddleschool-6fa40.appspot.com",
  messagingSenderId: "997131554190",
  appId: "1:997131554190:web:1b16cfa7a7e5b7a72868df",
  measurementId: "G-THQHHBHPFB",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [courses, setCourses] = useState([]);
  const [currentCourse, setCurrentCourse] = useState(0);
  const [currentUnit, setCurrentUnit] = useState(0);
  const [videos, setVideos] = useState({});

  useEffect(() => {
    retrieveCourses();
  }, []);

  const retrieveCourses = async () => {
    const courses = await getCourse(db);
    console.log(courses);
    setCourses(courses);
    // number of units in the course
    const units = Object.keys(courses[currentCourse].units).length;
    const unitString = "unit" + (currentUnit + 1);

    const videosInUnit = console.log(
      Object.keys(courses[currentCourse].units[unitString]).length
    );
    setVideos(courses[currentCourse].units[unitString]);
  };

  async function getCourse(db) {
    const courseCollection = collection(db, "courses");
    const courseSnapshot = await getDocs(courseCollection);
    const courseList = courseSnapshot.docs.map((doc) => doc.data());
    return courseList;
  }

  return (
    // <div className="App">
    //   {true ? (
    //     <Splash />
    //   ) : (
    //     <div>
    //       <Header loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
    //       <div>
    //         {loggedIn ? (
    //           <>
    //             <div
    //               style={{
    //                 backgroundColor: "#51789f",
    //                 height: "95vh",
    //               }}
    //             >
    //               <Course videos={videos} />
    //             </div>
    //           </>
    //         ) : (
    //           <LogInModal setLoggedIn={setLoggedIn} />
    //         )}
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div className="App">
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/progress" element={<Progress />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/home" element={<Home />} />
        <Route path="/radio" element={<Radio />} />
        <Route path="/store" element={<Store />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/circle" element={<Circle />} />
      </Routes>
    </div>
  );
}

export default App;
