import React, { useState, useEffect } from "react";
import FiddleAnnual from "../assets/fiddleAnnual.webp";
import HeaderImage from "../assets/Logo.png";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import "../App.css";
import HeaderSplash from "../components/HeaderSplash";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

export default function Offers(props) {
  const item = {
    price: "price_1PoWEiC0zrM5gWAWLe4SMUaN",
    quantity: 1,
  };
  const checkoutOptions = {
    lineItems: [item],
    mode: "subscription",
    successUrl: `${window.location.origin}/offers`,
    cancelUrl: `${window.location.origin}/offers`,
  };

  const fiddleAnnualProduct = {
    price: "price_1PoWI1C0zrM5gWAWXNmTUIrB",
    quantity: 1,
  };
  const checkoutOptionsFiddleAnnual = {
    lineItems: [fiddleAnnualProduct],
    mode: "subscription",
    successUrl: `${window.location.origin}/offers`,
    cancelUrl: `${window.location.origin}/offers`,
  };

  const redirectToCheckout = async (checkoutOptions) => {
    console.log("redirectToCheckout");
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);
  };

  return (
    <>
      <HeaderSplash page={"offers"} />
      {/* <div className="navbar">
        <div style={{ flex: 1, alignItems: "flex-start", display: "flex" }}>
          <img
            src={HeaderImage}
            style={{ width: 140, marginRight: 20, marginLeft: 80 }}
          />
        </div>
        <div
          style={{
            marginRight: 80,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            to={"/"}
            className={
              props.selected == 2
                ? "Header-Label Header-Selected"
                : "Header-Label"
            }
            style={{ textDecorationLine: "none", color: "white" }}
          >
            Home
          </Link>
          <div
            style={{
              cursor: "pointer",
            }}
            // onClick={() => {
            //   props.scrollToSection(props.refs[2]);
            // }}
            className={"nav-item"}
          >
            Memberships
          </div>

          <div
            onClick={() => {
              props.scrollToSection(props.refs[3]);
            }}
            style={{
              backgroundColor: "#832623",
              width: 120,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontWeight: "bold",
              borderRadius: 20,
              cursor: "pointer",
            }}
          >
            Log In
          </div>
        </div>
      </div> */}

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "22.5vh",
          marginBottom: "22.5vh",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            marginRight: "1%",
            height: 600,
            width: "40%",
            borderRadius: 5,
            textAlign: "left",
            paddingLeft: 25,
            cursor: "pointer",
            position: "relative",
            border: "2px solid #c0c0c0",
          }}
          className="StoreButton Button"
          onClick={() => {
            redirectToCheckout(checkoutOptionsFiddleAnnual);
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              paddingTop: 25,
              fontSize: 15,
              textTransform: "uppercase",
            }}
          >
            Signature Memberhsip
          </div>
          <div
            style={{
              fontWeight: "bold",
              paddingTop: 18,
              fontSize: 32,
              marginTop: -12,
            }}
          >
            Fiddle Annual
          </div>
          <img
            src={FiddleAnnual}
            style={{ width: "75%", marginTop: 20, borderRadius: 5 }}
          />
          <div style={{ fontSize: 16, marginTop: 20, fontWeight: "600" }}>
            <div style={{ marginTop: 2 }}>• 1000+ lessons from experts</div>
            <div style={{ marginTop: 2 }}>
              • 400+ tracks to listen and play along to
            </div>
            <div style={{ marginTop: 2 }}>
              • Community of hundreds of fiddlers
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: "#782a1d",
              width: "100%",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              flexDirection: "row",
              display: "flex",
              height: 75,
              color: "white",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                borderRight: "1.5px solid white",
              }}
            >
              <div style={{ marginTop: 2 }}>12 Month Sign Up</div>
              <div style={{ marginTop: 2 }}> $35 / month</div>
            </div>
            <div
              style={{
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: 2 }}> Month to Month</div>
              <div style={{ marginTop: 2 }}> $45 / month</div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            marginLeft: "1%",
            height: 600,
            width: "40%",
            borderRadius: 5,
            textAlign: "left",
            paddingLeft: 25,
            cursor: "pointer",
            position: "relative",
            border: "2px solid #c0c0c0",
          }}
          onClick={() => {
            redirectToCheckout(checkoutOptions);
          }}
          className="StoreButton Button"
        >
          <div
            style={{
              fontWeight: "bold",
              paddingTop: 25,
              fontSize: 15,
              textTransform: "uppercase",
            }}
          >
            Exclusive Membership
          </div>
          <div
            style={{
              fontWeight: "bold",
              paddingTop: 18,
              fontSize: 32,
              marginTop: -12,
            }}
          >
            Golden Fiddle Club
          </div>
          <img
            src={FiddleAnnual}
            style={{ width: "75%", marginTop: 20, borderRadius: 5 }}
          />
          <div style={{ fontSize: 16, marginTop: 20, fontWeight: "600" }}>
            <div style={{ marginTop: 2 }}>• 1000+ lessons from experts</div>
            <div style={{ marginTop: 2 }}>• Access to all webinars</div>
            <div style={{ marginTop: 2 }}>
              • Discounts on available store items and camps
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: "#51789f",
              width: "100%",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              flexDirection: "row",
              display: "flex",
              height: 75,
              color: "white",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                borderRight: "1.5px solid white",
              }}
            >
              <div style={{ marginTop: 2 }}>12 Month Sign Up</div>
              <div style={{ marginTop: 2 }}> $35 / month</div>
            </div>
            <div
              style={{
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: 2 }}> Month to Month</div>
              <div style={{ marginTop: 2 }}> $45 / month</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
