import React, { useState, useEffect, useRef } from "react";
import {
  FaPlay,
  FaPause,
  FaForward,
  FaBackward,
  FaRandom,
  FaRedo,
} from "react-icons/fa"; // Font Awesome Icons
import "../App.css";
import RadioWave from "./RadioWave";
import Play from "../assets/play.png";
import Pause from "../assets/pause.png";
// import WaveSurfer from "wavesurfer.js";
import WaveSurfer from "wavesurfer.js";
import { useNavigate } from "react-router-dom";
import { S3, GetObjectCommand } from "@aws-sdk/client-s3";

const s3Client = new S3({
  forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  endpoint: "https://nyc3.digitaloceanspaces.com",
  region: "us-east-1",
  credentials: {
    accessKeyId: "DO00BDB9MDHYVCQXK4RW",
    secretAccessKey: "at3hsgRuU3tSRXqGjG0ApMLAQ9yhqA22a0QTkosHfrY",
  },
});

const ControlBar = ({
  onPlayPause,
  onNext,
  onPrevious,
  onShuffle,
  trackTitle,
  trackGenre,
  onReplay,
  duration = 180,
  tuneFile, // total duration in seconds
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [countdown, setCountdown] = useState(3); // Countdown timer
  const [showOverlay, setShowOverlay] = useState(false); // Controls overlay visibility

  // Convert seconds to "minutes:seconds" format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleProgressChange = (event) => {
    const newTime = parseFloat(event.target.value);
    setCurrentTime(newTime);
    if (waveSurferRef.current) {
      waveSurferRef.current.seekTo(newTime / duration); // Seek based on percentage
    }
  };

  const [speed, setSpeed] = useState(1);
  const [file, setFile] = useState(null);
  const [playPause, setPlayPause] = useState(false);
  const [current, setCurrent] = useState(null);
  const waveSurferRef = useRef(null);

  const navigate = useNavigate();
  const getObj = async () => {
    const command = new GetObjectCommand({
      Bucket: "play-along-tracks",
      Key: tuneFile
        ? tuneFile
        : "lesson1/04 Boil The Cabbage Fast - Guitar.mp3",
    });
    try {
      const response = await s3Client.send(command);
      // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
      const test = await response.Body.transformToString("base64");
      setFile(test);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const val = getObj();
  }, [tuneFile]);

  // Initialize WaveSurfer once when the component mounts
  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: waveSurferRef.current,
      waveColor: "#7b2d28",
      progressColor: "#c0c0c0",
      height: 50,
      responsive: true,
    });

    // Clean up WaveSurfer instance on unmount
    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, []);

  // Handle track changes by loading new audio source and resetting play state
  useEffect(() => {
    if (file && waveSurferRef.current) {
      // Destroy existing audio instance before loading a new track
      waveSurferRef.current.destroy();
      setCurrentTime(0);
      // Re-create the WaveSurfer instance with the new audio source
      waveSurferRef.current = WaveSurfer.create({
        container: "#Wave",
        waveColor: "#7b2d28",
        progressColor: "#c0c0c0",
        height: 50,
        responsive: true,
      });

      // Load the new track
      waveSurferRef.current.load("data:audio/wav;base64," + file);

      // Update current time as the track plays
      waveSurferRef.current.on("audioprocess", () => {
        setCurrentTime(waveSurferRef.current.getCurrentTime());
      });
      // Reset play state and speed
      setIsPlaying(false);
      waveSurferRef.current.setPlaybackRate(speed);
    }
  }, [file, speed]);
  const [startCountdown, setStartCountdown] = useState(false);
  const [countdownToggle, setCountdownToggle] = useState(true);

  useEffect(() => {
    if (countdown > 0 && startCountdown) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setShowOverlay(false); // Hide overlay when countdown ends
      waveSurferRef.current.play(); // Start playing the track
      setIsPlaying(true);
      setStartCountdown(false);
    }
  }, [countdown]);

  const handlePlayPause = () => {
    setStartCountdown(true);
    if (countdownToggle == false) {
      waveSurferRef.current.playPause();
      setIsPlaying(waveSurferRef.current.isPlaying());
      // Start countdown when track is loaded
    } else if (isPlaying == true) {
      waveSurferRef.current.pause();
      setIsPlaying(waveSurferRef.current.isPlaying());
    } else if (waveSurferRef.current) {
      // waveSurferRef.current.playPause();
      setIsPlaying(waveSurferRef.current.isPlaying());
      // Start countdown when track is loaded
      setShowOverlay(true);
      setCountdown(5);
    }
  };

  // useEffect(() => {
  //   let preservePitch = true;
  //   const speeds = [0.25, 0.5, 1, 2, 4];

  //   var element = document.getElementById("Wave");
  //   if (element.firstChild) {
  //     // element.parentNode.removeChild(element);
  //     var old_element = document.getElementsByClassName("play-pause")[0];
  //     var new_element = old_element.cloneNode(true);
  //     // new_element.addEventListener("click", () => {
  //     //   setIsPlaying(!isPlaying);
  //     // });
  //     old_element.parentNode.replaceChild(new_element, old_element);
  //     var old_speed = document.getElementById("speed");
  //     var new_speed = old_speed.cloneNode(true);
  //     old_speed.parentNode.replaceChild(new_speed, old_speed);
  //     element.firstChild.remove();
  //   }

  //   if (file != null) {
  //     waveSurferRef.current = WaveSurfer.create({
  //       container: "#Wave",
  //       height: 50,
  //       width: 100,
  //       waveColor: "#7b2d28",
  //       progressColor: "#c0c0c0",
  //       audioRate: 1, // set the initial playback rate
  //     });

  //     waveSurferRef.current
  //       // .loadBlob("data:audio/wav;base64," + file)
  //       .load("data:audio/wav;base64," + file)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     waveSurferRef.current.on("click", () => {
  //       waveSurferRef.current.play();
  //     });

  //     // Set the playback rate
  //     document
  //       .querySelector('input[type="number"')
  //       .addEventListener("input", (e) => {
  //         const speed = e.target.value;
  //         console.log(e.target.valueAsNumber, parseInt(speed).toFixed(2));
  //         document.querySelector("#rate").textContent =
  //           parseInt(speed).toFixed(2);
  //         waveSurferRef.current.setPlaybackRate(speed, preservePitch);
  //         setSpeed(speed);
  //         waveSurferRef.current.play();
  //       });

  //     //   // Play/pause
  //     // document.querySelector("button").addEventListener("click", () => {
  //     //   wavesurfer.playPause();
  //     // });

  //     document
  //       .getElementsByClassName("play-pause")[0]
  //       .addEventListener("click", () => {
  //         console.log(isPlaying, !isPlaying);
  //         waveSurferRef.current.playPause();
  //       });
  //     console.log("we get here? ");
  //     // // Cleanup WaveSurfer instance on component unmount
  //     // return () => {
  //     //   wavesurfer.current.destroy();
  //     // };

  //     // return () => wavesurfer.un("finish", handleFinish);
  //   }
  // }, [file]);

  return (
    <div className="control-bar">
      {showOverlay && (
        <div
          className={`countdown-overlay ${countdown === 0 ? "fade-out" : ""}`}
        >
          <h1>{countdown > 0 ? countdown : ""}</h1>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          left: "6vw",
          minWidth: 300,
          textAlign: "left",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 18 }}>
          {trackTitle.length > 33
            ? trackTitle.slice(0, 33) + "..."
            : trackTitle}
        </div>
        <div style={{ fontWeight: "300", fontSize: 14, paddingTop: 4 }}>
          {trackGenre}
        </div>
      </div>
      <div>
        <button className="control-btn" onClick={onShuffle}>
          <FaRandom />
        </button>
        <button className="control-btn" onClick={onPrevious}>
          <FaBackward />
        </button>

        <button
          className="control-btn play-pause"
          onClick={() => {
            handlePlayPause();
          }}
        >
          <FaPause style={{ display: isPlaying ? "flex" : "none" }} />
          <FaPlay style={{ display: isPlaying ? "none" : "flex" }} />
        </button>

        <button className="control-btn" onClick={onNext}>
          <FaForward />
        </button>
        <button className="control-btn" onClick={onReplay}>
          <FaRedo />
        </button>

        <div className="progress-container">
          <span className="current-time">{formatTime(currentTime)}</span>
          <input
            type="range"
            className="progress-bar"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleProgressChange}
          />
          <span className="total-time">{formatTime(duration)}</span>
        </div>
      </div>{" "}
      <div
        style={{
          backgroundColor: "#70322A",
          color: "white",
          height: 50,
          width: 100,
          borderRadius: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          cursor: "pointer",
          opacity: countdownToggle ? 1 : 0.7,
          transition: "0.5s",
          marginLeft: 20,
        }}
        onClick={() => {
          setCountdownToggle(!countdownToggle);
        }}
      >
        {countdownToggle ? "Countdown Off" : "Countdown On"}
      </div>
      <div style={{ position: "absolute", right: 100, top: -25 }}>
        <>
          <div
            style={{
              width: 100,
              height: 150,
            }}
          >
            <div
              style={{
                marginTop: false == "small" ? 25 : 50,
                height: false == "small" ? 400 : 400,
              }}
              id="Wave"
              ref={waveSurferRef}
            />
            <div>
              <div
                style={{
                  display: "flex",
                  margin: "1rem 0",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    setPlayPause(!playPause);
                  }}
                  style={{
                    // backgroundColor: "black",
                    // color: "white",
                    // width: 100,
                    // height: 50,
                    borderRadius: 4,
                    border: "none",
                    cursor: "pointer",
                    fontWeight: "bold",
                    backgroundColor: "#f8f6f1",
                  }}
                >
                  <div id="play">
                    {playPause ? (
                      <img src={Pause} style={{ width: 25 }} />
                    ) : (
                      <img src={Play} style={{ width: 25 }} />
                    )}
                  </div>
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ fontSize: 16 }}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        paddingBottom: 10,
                      }}
                    >
                      Change Playback Rate
                    </div>
                    <input
                      // onChange={(event) => {
                      //   setSpeed(Number(event.target.value));
                      // }}

                      value={speed}
                      id="speed"
                      type="number"
                      min="0"
                      max="2"
                      style={{
                        width: 150,
                        height: 26,
                        borderRadius: 8,
                        borderWidth: 1,
                        textAlign: "center",
                        accentColor: "#7b2d28",
                      }}
                    />{" "}
                  </label>
                  <label style={{ fontSize: 12, marginTop: 8 }}>
                    Playback rate:{" "}
                    <span id="rate" style={{ fontWeight: "bold" }}>
                      1.00
                    </span>
                    <span style={{ fontWeight: "bold" }}>x</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ControlBar;
