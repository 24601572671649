import "../App.css";
import React, { useState, useEffect, useRef } from "react";
import HeaderImage from "../assets/Logo.png";
import DownArrow from "../assets/down-arrow.png";
import { Link } from "react-router-dom";
import FiddleAnnual from "../assets/fiddleAnnual.webp";
import PrivateLessons from "../assets/Private-Lessons.webp";
import TexasTunes from "../assets/TexasTunesMay2024.webp";
import SwingImprov from "../assets/SwingImprovMay2024.webp";
import nightingale from "../assets/nightingale.webp";
import lockwood from "../assets/lockwood.webp";
import oldfashioned from "../assets/oldfashioned.webp";
import morningstar from "../assets/morningstar.webp";
import volume1 from "../assets/volume1.webp";
import volume2 from "../assets/volume2.webp";
import volume3 from "../assets/volume3.webp";
import completeSet from "../assets/completeSet.webp";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

export default function Store(props) {
  const redirectToCheckout = async (checkoutOptions) => {
    console.log("redirectToCheckout");
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);
  };

  const first = useRef(null);
  const second = useRef(null);
  const third = useRef(null);
  const fourth = useRef(null);
  const fifth = useRef(null);
  const sixth = useRef(null);

  const sections = ["Webinars", "Fiddles", "Books", "Lessons"];
  const scrollToSection = (index) => {
    let scroll = 0;
    if (index == 0) scroll = 30;
    else if (index == 1) {
      scroll = 370;
    } else if (index == 2) {
      scroll = 755;
    } else if (index == 3) {
      scroll = 1160;
    } else if (index == 4) {
      scroll = 1850;
    }
    console.log(scroll, index);
    window.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
  };
  const redirect = (priceId) => {
    const item = {
      price: priceId,
      quantity: 1,
    };
    const checkoutOptions = {
      lineItems: [item],
      mode: "payment",
      successUrl: `${window.location.origin}/store`,
      cancelUrl: `${window.location.origin}/store`,
    };
    redirectToCheckout(checkoutOptions);
  };
  useEffect(() => {
    console.log(process.env.REACT_APP_STRIPE_KEY);
  }, []);
  const [section, setSelectedSection] = useState("Memberships");
  return (
    <>
      <div
        style={{
          top: 0,
          width: "100vw",
          //   height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "14.5vh",
        }}
      >
        <div>
          <div
            style={{
              top: 0,
              position: "fixed",
              right: "0vw",
              width: "100vw",
              flexDirection: "row",
              display: "flex",
              marginTop: 0,
              backgroundColor: "#f8f6f1",
              zIndex: 1000,
              height: 70,
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1, alignItems: "flex-start", display: "flex" }}>
              <img
                src={HeaderImage}
                style={{ width: 140, marginRight: 20, marginLeft: 80 }}
              />
            </div>
            <div
              style={{
                marginRight: 80,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to={"/"}
                className={
                  props.selected == 2
                    ? "Header-Label Header-Selected"
                    : "Header-Label"
                }
                style={{ textDecorationLine: "none" }}
              >
                Home
              </Link>
              <Link
                to={"/offers"}
                className={
                  props.selected == 2
                    ? "Header-Label Header-Selected"
                    : "Header-Label"
                }
                style={{ textDecorationLine: "none" }}
              >
                Memberships
              </Link>
              <div
                style={{
                  color: "black",
                  textDecoration: "none",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   props.scrollToSection(props.refs[2]);
                // }}
                className={"Header-Label"}
              >
                Store
              </div>
              <div
                onClick={() => {
                  props.scrollToSection(props.refs[3]);
                }}
                style={{
                  backgroundColor: "#832623",
                  width: 120,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: 20,
                  cursor: "pointer",
                }}
              >
                Login
              </div>
            </div>
          </div>
          <div
            style={{
              top: 70,
              position: "fixed",
              right: "0vw",
              width: "100vw",
              flexDirection: "row",
              display: "flex",
              marginTop: 0,
              backgroundColor: "#832216",
              zIndex: 1000,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "2.5px solid #847866",
            }}
          >
            {sections.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    // setSelectedSection(item);
                    let refs = [first, second, third, fourth, fifth, sixth];
                    scrollToSection(index);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    marginLeft: 20,
                    marginRight: 20,
                    fontWeight: item == section ? "bold" : "500",
                    fontSize: 14,
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        {section == "Memberships" ? (
          <>
            <div ref={second}></div>
            <div
              style={{
                width: "100%",
                marginTop: 25,
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderRadius: 5,
                  textAlign: "left",
                  paddingLeft: 25,
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textTransform: "uppercase",
                      paddingTop: 15,
                      paddingBottom: 15,
                      borderRadius: 4,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      border: "2px solid #c0c0c0",
                      //   textDecorationLine: "underline",
                    }}
                  >
                    Live Webinars With Katie Glassman
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      paddingTop: 5,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      className="Button StoreBtn BtnHeight1"
                      onClick={() => {
                        redirect("price_1PoWLRC0zrM5gWAWtVuiZgFy");
                      }}
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 175,
                          height: 175,
                          borderRadius: 2,
                          marginTop: 0,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 5,
                          fontSize: 12,
                          marginTop: 15,
                          fontWeight: "bold",
                        }}
                      >
                        Texas Tunes 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $154.00
                      </div>
                    </div>
                    <div
                      className="Button StoreBtn BtnHeight1"
                      onClick={() => {
                        redirect("price_1PoWM5C0zrM5gWAWLSBjJQp0");
                      }}
                    >
                      <img
                        src={SwingImprov}
                        style={{
                          width: 175,
                          height: 175,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 15,
                          fontWeight: "bold",
                        }}
                      >
                        Swing Improv 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $154.00
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textTransform: "uppercase",
                      paddingTop: 15,
                      paddingBottom: 15,
                      borderRadius: 4,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      border: "2px solid #c0c0c0",
                      marginTop: 30,
                    }}
                    ref={fifth}
                  >
                    Fiddles For Sale
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      paddingTop: 5,
                    }}
                  >
                    <div
                      className="Button StoreBtn BtnHeight2"
                      onClick={() => {
                        redirect("price_1PoWSBC0zrM5gWAWjGE8MvZL");
                      }}
                    >
                      <div
                        style={{
                          width: 175,
                          borderBottomWidth: 1,
                          borderBottomColor: "grey",
                          borderBottomStyle: "solid",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={morningstar}
                          style={{
                            width: 140,
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: 10,
                          }}
                        />
                      </div>

                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Morningstar: Beginner
                        <br />
                        Fiddler Package
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 4,
                        }}
                      >
                        $700.00
                      </div>
                    </div>
                    <div
                      className="Button StoreBtn BtnHeight2"
                      onClick={() => {
                        redirect("price_1PoWVOC0zrM5gWAWC8GYuNlA");
                      }}
                    >
                      <div
                        style={{
                          width: 175,
                          borderBottomWidth: 1,
                          borderBottomColor: "grey",
                          borderBottomStyle: "solid",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={oldfashioned}
                          style={{
                            width: 140,
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 10,
                          fontWeight: "bold",
                        }}
                      >
                        The Old Fashioned
                        <br />
                        Electro-Acoustic
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 4,
                        }}
                      >
                        $1600.00
                      </div>
                    </div>
                    <div
                      className="Button StoreBtn BtnHeight2"
                      onClick={() => {
                        redirect("price_1PoWWqC0zrM5gWAWcFlWdOjy");
                      }}
                    >
                      <div
                        style={{
                          width: 175,
                          borderBottomWidth: 1,
                          borderBottomColor: "grey",
                          borderBottomStyle: "solid",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={lockwood}
                          style={{
                            width: 140,
                            height: 210,
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 10,
                          fontWeight: "bold",
                        }}
                      >
                        The Lockwood
                        <br />
                        Electro-Acoustic
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 4,
                        }}
                      >
                        $2400.00
                      </div>
                    </div>
                    <div
                      className="Button StoreBtn BtnHeight2"
                      onClick={() => {
                        redirect("price_1PoWYZC0zrM5gWAWcQwp5yiO");
                      }}
                    >
                      <div
                        style={{
                          width: 175,
                          borderBottomWidth: 1,
                          borderBottomColor: "grey",
                          borderBottomStyle: "solid",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={nightingale}
                          style={{
                            width: 140,
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 10,
                          fontWeight: "bold",
                        }}
                      >
                        The Nightingale
                        <br />
                        Electro-Acoustic
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 4,
                        }}
                      >
                        $2625.00
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textTransform: "uppercase",
                      paddingTop: 15,
                      paddingBottom: 15,
                      borderRadius: 4,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      border: "2px solid #c0c0c0",
                      marginTop: 30,
                    }}
                  >
                    Fiddle School Books
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="Button StoreBtn BtnHeight3"
                      style={{
                        maxWidth: 175,
                      }}
                      onClick={() => {
                        redirect("price_1PoWZoC0zrM5gWAWYsZfthlF");
                      }}
                    >
                      <img
                        src={volume1}
                        style={{
                          width: 175,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book Volume 1 (New Edition!)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $35.00
                      </div>
                    </div>
                    <div
                      style={{
                        maxWidth: 175,
                        overflow: "hidden",
                      }}
                      className="Button StoreBtn BtnHeight3"
                      onClick={() => {
                        redirect("price_1PoWb0C0zrM5gWAWCvkbESNN");
                      }}
                    >
                      <img
                        src={volume2}
                        style={{
                          width: 175,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book Volume 2 (New Edition!)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $35.00
                      </div>
                    </div>
                    <div
                      style={{
                        maxWidth: 175,
                      }}
                      className="Button StoreBtn BtnHeight3"
                      onClick={() => {
                        redirect("price_1PoWcdC0zrM5gWAWbN85DJ7W");
                      }}
                    >
                      <img
                        src={volume3}
                        style={{
                          width: 175,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book (Volume 3)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $35.00
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="Button StoreBtn BtnHeight3"
                      onClick={() => {
                        redirect("price_1PoWeFC0zrM5gWAWUYttxVqX");
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid gray",
                          paddingBottom: 50,
                        }}
                      >
                        <img
                          src={completeSet}
                          style={{
                            width: 175,
                            borderRadius: 2,
                            marginTop: 40,
                          }}
                        />
                      </div>

                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book Bundle (Complete Set)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $95.00
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ marginLeft: "1%", marginRight: "1%" }}
                  ref={sixth}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textTransform: "uppercase",
                      paddingTop: 15,
                      paddingBottom: 15,
                      borderRadius: 4,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      border: "2px solid #c0c0c0",
                      marginTop: 30,
                    }}
                  >
                    Private Lessons
                  </div>
                  <div className="Button StoreBtn BtnHeight1" style={{}}>
                    <img
                      src={PrivateLessons}
                      style={{
                        width: 175,
                        height: 175,
                        borderRadius: 2,
                      }}
                    />
                    <div
                      style={{
                        width: "80%",
                        marginRight: 8,
                        fontSize: 12,
                        fontWeight: "900",
                        opacity: 0.75,
                        marginTop: 5,
                        marginTop: 12,
                      }}
                    >
                      $54.00-$90.00 / lesson
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "1%",
                    marginRight: "1%",
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textTransform: "uppercase",
                      paddingTop: 15,
                      paddingBottom: 15,
                      borderRadius: 4,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      border: "2px solid #c0c0c0",
                      marginTop: 30,
                    }}
                  >
                    Recorded & Archived Webinars
                  </div>
                  <div
                    style={{
                      paddingTop: 5,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Irish Tunes: Learn a Complete Set
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $50.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Listening Library
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Archived Improv Workouts
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Historical Interviews
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Archived Texas Tunes
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Guitar Backups 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Guitar Backups 2
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Jamming Skills 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $50.00
                      </div>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Jamming Skills 2
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $150.00
                      </div>
                    </div>
                    <div
                      style={{ maxWidth: 160 }}
                      className="Button StoreBtn BtnHeight4"
                    >
                      <img
                        src={TexasTunes}
                        style={{
                          width: 160,
                          height: 140,
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 12,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Theory 101: A Fiddler’s Toolkit
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "600",
                          opacity: 0.75,
                          marginTop: 5,
                        }}
                      >
                        $150.00
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 30,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 20,
                      fontSize: 16,
                      textTransform: "uppercase",
                      textAlign: "right",
                    }}
                  >
                    Camp & Retreats
                  </div>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: 250,
                      justifyContent: "center",
                      alignItems: "flex-end",
                      //   alignItems: "fl",
                      textAlign: "right",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#6f2f22",
                        fontSize: 24,
                        marginTop: 15,
                      }}
                    >
                      Fiddle School Mountain Retreat
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: 16,
                        marginTop: 15,
                        textAlign: "right",
                      }}
                    >
                      August 22-24, 2024
                    </div>
                    <div
                      className="Button"
                      style={{
                        fontWeight: "bold",
                        marginTop: 20,
                        width: 160,
                        height: 40,
                        borderRadius: 18,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: "#6f2f22",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Join wait list
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        marginTop: 15,
                      }}
                    >
                      If a spot opens up, we will contact you!
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
