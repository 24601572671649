import React from "react";
import Confetti from "react-confetti";

const CongratulationsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Only render if open
  return (
    <div className="modal-background">
      <div className="modal-container">
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200} // Increase for a fuller effect
          recycle={false} // Confetti falls once and then stops
        />
        <h2 className="modal-title">Congratulations!</h2>
        <p className="modal-message">
          Great job completing this section! Keep up the awesome work. Remember
          it takes time to become a great fiddler, keep practicing a little bit
          every day (5 - 10 minutes), and you'll set yourself up for success.
        </p>
        <button className="modal-button-close modal-button" onClick={onClose}>
          Go Back
        </button>
        <button className="modal-button" onClick={onClose}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default CongratulationsModal;
