import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import FiddleLong from "../assets/fiddle-long.png";
import Header from "../components/Header";
import HeaderSplash from "../components/HeaderSplash";
import HeaderImage from "../assets/Logo.png";
import DownArrow from "../assets/down-arrow.png";
import FiddleApproach from "../assets/FiddleApproach.png";
import CelesteHeadshot from "../assets/celeste.png";
import KatieHeadshot from "../assets/katie.png";

import "../App.css";

export default function Splash(props) {
  const zeroPage = useRef(null);
  const firstPage = useRef(null);
  const secondPage = useRef(null);
  const thirdPage = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef == 0 ? 0 : elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <div
        style={{
          position: "sticky",
          left: 20,
          bottom: 20,
          color: "black",
          textDecorationLine: "underline",
          cursor: "pointer",
          marginTop: 20,
        }}
        onClick={() => {
          scrollToSection(0);
        }}
      >
        Scroll To Top
      </div> */}

      <button
        className={`scroll-to-top`}
        onClick={() => {
          scrollToSection(0);
        }}
        aria-label="Scroll to top"
      >
        <span className="scroll-to-top-icon">↑</span>{" "}
        {/* You can use an icon here */}
      </button>

      <HeaderSplash
        refs={[firstPage, secondPage, thirdPage, zeroPage]}
        scrollToSection={scrollToSection}
        selected={0}
      />
      <div
        style={{
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f8f6f1",
        }}
        ref={zeroPage}
      >
        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            width: "0vw",
            height: "100vh",
            left: 0,
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            width: "0vw",
            height: "100vh",
            right: 0,
          }}
        ></div>
        <div style={{ marginBottom: 100 }}>
          <img src={HeaderImage} style={{ width: 300 }} />
          <div style={{ flexDirection: "row", marginTop: 20 }}>
            <Link to="login" style={{ color: "black", textDecoration: "none" }}>
              <div
                className="splash-button"
                style={{
                  border: "3px solid #832623",
                  backgroundColor: "white",
                }}
              >
                <p style={{ fontWeight: "bold" }}>Log In</p>
              </div>
            </Link>
            <Link
              to="signup"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div
                className="splash-button"
                style={{
                  border: "3px solid #222326",
                  marginTop: 10,
                  backgroundColor: "white",
                }}
              >
                <p style={{ fontWeight: "bold" }}>Sign Up</p>
              </div>
            </Link>
            <div style={{ marginTop: 15 }}>
              <strong>Note: this page is under construction..</strong>
              <br />
              updating to look like the show it site
            </div>
            {/* <Link to="store" style={{ color: "black", textDecoration: "none" }}>
              <div
                className="splash-button"
                style={{
                  border: "3px solid #222326",
                  marginTop: 10,
                  backgroundColor: "white",
                }}
              >
                <p style={{ fontWeight: "bold" }}>Store</p>
              </div>
            </Link> */}
            {/* <Link
              to="signup"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div
                className="splash-button"
                style={{
                  border: "3px solid #847866",
                  marginTop: 10,
                }}
              >
                <p style={{ fontWeight: "bold" }}>Store</p>
              </div>
            </Link> */}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            cursor: "pointer",
            bottom: 40,
          }}
        >
          <div
            className="arrow bounce"
            onClick={() => {
              scrollToSection(firstPage);
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 12,
                fontSize: 18,
              }}
            >
              Learn More
            </p>
            <img src={DownArrow} style={{ width: 40 }} />
          </div>
        </div>
      </div>
      <div
        ref={firstPage}
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f8f6f1",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "50%",
              alignItems: "flex-end",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              fontSize: 24,
              textAlign: "left",
            }}
          >
            <div
              style={{
                width: 450,
                height: "85vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: 50,
              }}
            >
              <div
                style={{
                  height: 550,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 48,
                    marginBottom: 25,
                    marginTop: 0,
                  }}
                >
                  About Fiddle School
                </p>
                <p style={{ marginTop: 0, paddingTop: 0, fontSize: 22 }}>
                  So many people want to learn to fiddle, pick it up again, or
                  improve their playing but don’t know where to start. That’s
                  why we created Fiddle School:{" "}
                  <strong>to give you a path to musical progress</strong>.
                </p>
                <p style={{ textAlign: "left" }}>
                  Fiddle School is built on{" "}
                  <strong>supportive community</strong>,{" "}
                  <strong>consistent feedback</strong>, and{" "}
                  <strong>friendly accountability</strong>. It’s a place to
                  advance your fiddling step-by-step at your own pace, wherever
                  you are in the learning process. The beloved tunes you’ll
                  learn here will help you feel at home on the fiddle, be
                  confident in jam sessions, and experience the joy of musical
                  growth.
                  <strong>Welcome to the jam!</strong>
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                height: "85vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: 550,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  marginTop: 15,
                }}
              >
                <img src={FiddleLong} style={{ width: 375, borderRadius: 4 }} />
                <div>
                  <p style={{ fontSize: 12, width: 375, textAlign: "left" }}>
                    <strong>Katie Glassman - Fiddle school founder:</strong> 25
                    years teaching experience, 6-time National Fiddle Champion,
                    7-time Colorado State Fiddle Champion
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "188vh",
              left: "46vw",
            }}
          >
            <div
              className="arrow bounce"
              onClick={() => {
                scrollToSection(secondPage);
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 12,
                  fontSize: 18,
                  color: "#3e3125",
                }}
              >
                Our Method
              </p>

              <img src={DownArrow} style={{ width: 40 }} />
            </div>
          </div>
        </div>
      </div>
      <div
        ref={secondPage}
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f8f6f1",
        }}
      >
        {/* <Header /> */}

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: 550,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "#3e3125",
                  fontWeight: "bold",
                  fontSize: 40,
                  marginBottom: 10,
                  marginTop: 0,
                }}
              >
                THE FIDDLE SCHOOL APPROACH
              </p>
              <p
                style={{
                  color: "#832216",
                  fontWeight: "bold",
                  fontSize: 30,
                  marginTop: 10,
                }}
              >
                LEARN, LISTEN, BUILD, PLAY
              </p>
              <img src={FiddleApproach} style={{ width: 750 }} />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "288vh",
              left: "46vw",
            }}
          >
            <div
              className="arrow bounce"
              onClick={() => {
                scrollToSection(thirdPage);
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 12,
                  fontSize: 18,
                  color: "#3e3125",
                }}
              >
                Our Instructors
              </p>

              <img src={DownArrow} style={{ width: 40 }} />
            </div>
          </div>
        </div>
      </div>
      <div
        ref={thirdPage}
        style={{
          height: "100vh",
          backgroundColor: "#f8f6f1",
          display: "flex",
          width: "100vw",
        }}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: 550,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "#3e3125",
                  fontWeight: "bold",
                  fontSize: 40,
                  marginBottom: 10,
                  marginTop: 0,
                }}
              >
                Fiddle School Teachers
              </p>
              <div
                style={{ flexDirection: "row", display: "flex", marginTop: 20 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 20,
                    width: 350,
                    textAlign: "left",
                  }}
                >
                  <img src={KatieHeadshot} style={{ width: 350 }} />
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      marginBottom: 5,
                    }}
                  >
                    Katie Glassman
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      marginBottom: 0,
                    }}
                  >
                    Fiddle School Founder • CEO • Fiddle Champion
                  </p>
                  <p style={{ fontSize: 14, marginBottom: 5 }}>
                    Katie Glassman is that rare musician who possesses a
                    combination of instrumental virtuosity and powerful musical
                    expression. She is one of the country’s most renowned and
                    decorated Texas-style and swing fiddlers, as well as an
                    accomplished songwriter, singer, and a highly sought after
                    teacher.
                  </p>
                  <p style={{ fontSize: 14, marginTop: 5 }}>
                    Based in Denver, Colorado, Katie toured and recorded with
                    The Western Flyers, and currently leads her own band, Katie
                    Glassman and Snapshot. She also maintains a thriving private
                    teaching practice and travels to teach at music camps
                    throughout the country. Since she began her Fiddle Parlor
                    teaching studio in 1994, Katie has been enthusiastically
                    educating Texas-style fiddlers of all ages, many of whom
                    have gone on to become National and state champions.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 20,
                    textAlign: "left",
                    width: 350,
                  }}
                >
                  <img src={CelesteHeadshot} style={{ width: 350 }} />

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      marginBottom: 5,
                    }}
                  >
                    Celeste Johnson
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: 12 }}>
                    Fiddle School Instructor • Fiddle Champion
                  </p>
                  <p style={{ fontSize: 14, marginBottom: 5 }}>
                    Celeste Johnson’s roots in Texas-style fiddling reach all
                    the way back to when she began playing at five years old.
                    She grew up in Arvada, Colorado, where she discovered her
                    passion for fiddling and nourished it thanks to the
                    wonderful fiddle community in Colorado. She entered her
                    first fiddle contest when she was seven and has since
                    continued on to become a five-time national champion fiddler
                    in her division, the 2019 Walnut Valley Fiddle Champion, a
                    repeat state division champion, and a judge at several
                    fiddle contests.
                  </p>
                  <p style={{ fontSize: 14, marginTop: 5 }}>
                    Her passion for Texas-style fiddling has also grown into a
                    passion for teaching it. She strives to help students become
                    solid, authentic fiddlers firmly rooted in the tradition of
                    this music and the example of the great fiddlers who made it
                    what it is. In addition to growing her own teaching studio,
                    Celeste has been teaching and working with Fiddle School
                    since its beginnings in 2016.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
