import React, { useState, useEffect } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

export default function Circle(props) {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100vw",
            // top: 50,
            top: 0,
            left: 0,
            backgroundColor: "#f0ebe0",
            height: 65,
            overflow: "hidden",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              marginLeft: 85,
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: 22 }}>
              Welcome Back, Kyle!
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/profile");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Home
          </div>
          <div
            onClick={() => {
              navigate("/home");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Videos
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            onClick={() => {
              navigate("/radio");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Radio
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            // onClick={() => {
            //   navigate("/profile");
            // }}
            style={{ marginRight: 220, cursor: "pointer", fontWeight: "bold" }}
          >
            Community
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
        </div>
        <div>
          ​
          <iframe
            style={{
              border: 0,
              boxShadow: "none",
              width: "100vw",
              height: "100vh",
            }}
            src="https://fiddle-school.circle.so/"
          ></iframe>
        </div>
      </div>
    </>
  );
}
