import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from "react";
import "../App.css";
import Calendar from "../assets/calendar.png";
import SMS from "../assets/sms.png";
import Play from "../assets/play.png";
import Pause from "../assets/pause.png";
import Circle from "../assets/Circle2.avif";
import video1 from "../assets/video1Screenshot.png";
import HeaderImage from "../assets/Logo.png";

import { FaMusic } from "react-icons/fa"; // Font Awesome Icons

import video1Screenshot from "../assets/video1Screenshot.png";

// import WaveSurfer from "wavesurfer.js";
import WaveSurfer from "wavesurfer.js";
import { useNavigate } from "react-router-dom";
export default function Profile(props) {
  const [speed, setSpeed] = useState(1);
  const [playPause, setPlayPause] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   const audioURL = require("../assets/testing.mp3");
  //   const audio = new Audio(audioURL); // assets folder in root project folder for Vanilla JS
  //   console.log(audio);
  //   let preservePitch = true;
  //   const speeds = [0.25, 0.5, 1, 2, 4];
  //   const wavesurfer = WaveSurfer.create({
  //     container: "#Wave",
  //     height: 200,
  //     width: 300,
  //     waveColor: "white",
  //     progressColor: "#c0c0c0",
  //     audioRate: 1, // set the initial playback rate
  //   });
  //   wavesurfer.load(audioURL);
  //   wavesurfer.on("click", () => {
  //     wavesurfer.play();
  //   });

  //   // Set the playback rate
  //   // document
  //   //   .querySelector('input[type="range"]')
  //   //   .addEventListener("input", (e) => {
  //   //     const speed = speeds[e.target.valueAsNumber];
  //   //     document.querySelector("#rate").textContent = speed.toFixed(2);
  //   //     wavesurfer.setPlaybackRate(speed, preservePitch);
  //   //     wavesurfer.play();
  //   //   });
  //   // //   // Play/pause
  //   // document.querySelector("button").addEventListener("click", () => {
  //   //   wavesurfer.playPause();
  //   // });
  // }, []);

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="navbar">
          <div
            style={{
              marginLeft: 85,
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <img src={HeaderImage} style={{ width: 180, height: 40 }} /> */}
          </div>
          <div
            onClick={() => {
              navigate("/profile");
            }}
            className="nav-item-selected"
          >
            Home
          </div>
          <div
            onClick={() => {
              navigate("/home");
            }}
            className="nav-item nav-item-unselected"
          >
            Videos
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            onClick={() => {
              navigate("/radio");
            }}
            className="nav-item"
          >
            Radio
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            // onClick={() => {
            //   navigate("/profile");
            // }}
            className="nav-item"
            style={{ marginRight: 220 }}
            onClick={() => {
              alert(
                "Once we transfer the domain, we can iframe circle communnity in to the page"
              );
            }}
          >
            Community
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "100px",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          {/* Main Content */}
          <main className="dashboard-main">
            <section className="action-cards">
              <ActionCard
                coverImage={video1}
                title="Today's Lesson"
                description="Starter Kit: Unit 1, Video 1"
                onClick={() => navigate("/home")}
              />
              <ActionCard
                image={Play}
                title="Fiddle School Radio"
                description="Play along: Dixie Hoedown with fast guitar..."
                onClick={() => navigate("/radio")}
              />
              <ActionCard
                title="Community"
                image={Circle}
                description="Connect with other Fiddlers!"
                onClick={() => {
                  alert(
                    "Once we transfer the domain, we can iframe circle communnity in to the page"
                  );
                }}
              />
              {/* <ActionCard
                title="Notes and Progress"
                description="Track your progress, reflect on your fiddle journey"
                onClick={() => navigate("/progress")}
              /> */}
            </section>
            <section className="progress-section">
              <h2 style={{ fontSize: 28, color: "black", marginTop: 10 }}>
                Progress
              </h2>
              <div style={{ fontSize: 22, marginBottom: 25, color: "black" }}>
                Lesson 1
              </div>
              <div className="progress-bar-profile">
                <div
                  className="progress-bar-fill-profile"
                  style={{ width: "20%" }}
                ></div>
              </div>
              <div className="progress-stats">
                <div className="stat-card">
                  <p style={{ fontWeight: "500", fontSize: 18 }}>
                    Days in a Row
                  </p>
                  <div
                    style={{ fontSize: 44, marginTop: 28, fontWeight: "bold" }}
                  >
                    1
                  </div>
                </div>
                <div className="stat-card">
                  <p style={{ fontWeight: "500", fontSize: 18 }}>
                    Total Time Practicing
                  </p>
                  <div
                    style={{
                      fontSize: 44,
                      marginTop: 28,
                      fontWeight: "bold",
                    }}
                  >
                    1 hr
                  </div>
                </div>
                <div className="stat-card">
                  <p style={{ fontWeight: "500", fontSize: 18 }}>
                    Estimated Lesson Left
                  </p>
                  <div
                    style={{
                      fontSize: 44,
                      marginTop: 28,
                      fontWeight: "bold",
                    }}
                  >
                    7 hrs
                  </div>
                </div>
              </div>
              {/* <div className="divider"></div>
              <h2>Pro tip</h2>
              <p>
                <strong> Daily practice is essential!</strong> Try to carve out
                15-45 minutes per day to practice fundamentals.
              </p>
              <div className="reminder-buttons">
                <button className="reminder-btn">Text Reminder</button>
                <button className="reminder-btn">Set Calendar Reminder</button>
              </div> */}
            </section>
            {/* <section className="reminder-section">
              <h2 style={{ fontSize: 28, color: "black", marginTop: 0 }}>
                This Month In Fiddle School
              </h2>
            </section> */}
          </main>
        </div>
      </div>
    </>
  );
}

const ActionCard = ({
  title,
  description,
  image,
  icon,
  onClick,
  coverImage,
}) => (
  <div
    className="action-card"
    onClick={onClick}
    style={{ position: "relative" }}
  >
    {coverImage && (
      <img
        src={coverImage}
        style={{
          width: "100%",
          height: 160,
          position: "absolute",
          top: 0,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,

          objectFit: "cover",
        }}
        alt={title}
        className="action-card-image"
      />
    )}

    {image && <img src={image} alt={title} className="action-card-image" />}
    {icon && <img src={icon} alt={title} className="action-card-icon" />}
    <div style={{ marginTop: coverImage ? 80 : 0 }}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);
