import React from "react";
import HeaderImage from "../assets/Logo.png";
import { Link } from "react-router-dom";

export default function HeaderSplash(props) {
  return (
    <>
      <div>
        <div className="navbar-home navbar">
          <div style={{ flex: 1, alignItems: "flex-start", display: "flex" }}>
            <img
              src={HeaderImage}
              style={{ width: 140, marginRight: 20, marginLeft: 80 }}
            />
          </div>
          <div
            style={{
              marginRight: 80,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.page == "offers" ? (
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className="nav-item"
              >
                Home
              </Link>
            ) : (
              <div
                onClick={() => {
                  // props.scrollToSection(props.refs[0]);
                }}
                className="nav-item-selected"
              >
                Home
              </div>
            )}

            {props.page == "offers" ? (
              <div
                to="offers"
                style={{ textDecoration: "none" }}
                className="nav-item-selected"
              >
                Memberships
              </div>
            ) : (
              <Link
                to="offers"
                style={{ textDecoration: "none" }}
                className="nav-item"
              >
                Memberships
              </Link>
            )}

            {/* <Link
              to="store"
              style={{ color: "black", textDecoration: "none" }}
              // onClick={() => {
              //   props.scrollToSection(props.refs[2]);
              // }}
              className={"Header-Label"}
            >
              Store
            </Link> */}
            <Link
              className="Button ButtonGrow"
              style={{
                backgroundColor: "#c0c0c0",
                width: 100,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
                borderRadius: 20,
                cursor: "pointer",
                textDecorationLine: "none",
              }}
              to={"/login"}
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
