import React, { useState, useEffect } from "react";
import HeaderImage from "../assets/Logo.png";
import DownArrow from "../assets/down-arrow.png";

import "../App.css";
import SignUpModal from "../components/SignUpModal";
import Hamburger from "../assets/Hamburger.png";
import { useNavigate } from "react-router-dom";
import ControlBar from "../components/ControlBar";
import RadioSidebar from "../components/RadioSidebar";

export default function Radio(props) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [trackType, setTrackType] = useState(0);

  const [selectedTrack, setSelectedTrack] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const nextTrack = () => {
    setSelectedTrack(selectedTrack + 1);
    console.log("Next track");
  };

  const previousTrack = () => {
    if (selectedTrack > 0) setSelectedTrack(selectedTrack - 1);
    console.log("Previous track");
  };

  const shuffleTracks = () => {
    console.log("Shuffle tracks");
  };

  const replayTrack = () => {
    console.log("Replay track");
  };

  let trackTypes = [
    "Fiddle School Slow Tracks",
    "Fiddle School Fast Guitar Backup",
    "Fiddle School Tunes Station",
  ];

  let tuneFiles = [
    [
      "lesson1/01 Boil The Cabbage Slow - Fiddle and Guitar.mp3",
      "lesson1/09 Oh! Susanna Slow - Fiddle and Guitar.mp3",
      "lesson2/06 Liza Jane Slow - Guitar.mp3",
      "lesson2/14 I Don_t Love Nobody Slow - Guitar.mp3",
      "lesson3/18 Cotton-Eyed Joe Slow - Guitar.mp3",
      "lesson3/22 Goodbye Liza Jane Slow - Guitar.mp3",
      "lesson4/30 The Girl I Left Behind Me Slow - Guitar.mp3",
      "lesson4/26 Peek-A-Boo Waltz Slow - Guitar.mp3",
      "lesson5/35 Arkansas Traveler Slow - Guitar.mp3",
      "lesson5/38 Red River Valley Slow - Guitar.mp3",
      "lesson6/46 8th of January Slow - Guitar.mp3",
      "lesson6/54 Tennessee Waltz Slow - Guitar.mp3",
      "lesson7/50 Redwing Slow - Guitar.mp3",
      "lesson7/70 Bonaparte_s Retreat Slow - Guitar.mp3",
      "lesson8/58 Soldier_s Joy Slow - Guitar.mp3",
      "lesson8/63 Corrina Corrina Slow - Guitar.mp3",
      "lesson9/74 Marie Slow - Guitar.mp3",
      "lesson9/78 Golden Slippers Slow - Guitar.mp3",
      "lesson10/82 Liberty Slow - Guitar.mp3",
      "lesson10/88 Tears On My Pillow - Guitar.mp3",
      "lesson11/90 Wind That Shakes the Barley Slow - Guitar.mp3",
      "lesson11/94 East Texas Drag Slow - Guitar.mp3",
      "lesson12/97 Old Joe Clark Slow - Guitar.mp3",
      "lesson12/101 Pig Ankle Rag Slow - Guitar.mp3",
    ],
    [
      "lesson1/03 Boil The Cabbage Fast - Fiddle and Guitar.mp3",
      "lesson1/12 Oh! Susanna Fast - Guitar.mp3",
      "lesson2/08 Liza Jane Fast - Guitar.mp3",
      "lesson2/16 I Don_t Love Nobody Fast - Guitar.mp3",
      "lesson3/20 Cotton-Eyed Joe Fast - Guitar.mp3",
      "lesson3/24 Goodbye Liza Jane Fast - Guitar.mp3",
      "lesson4/32 The Girl I Left Behind Me Fast - Guitar.mp3",
      "lesson4/28 Peek-A-Boo Waltz Fast - Guitar.mp3",
      "lesson5/36ArkansasTravelerFastGuitar.mp3",
      "lesson5/40 Red River Valley Fast - Guitar.mp3",
      "lesson6/48 8th of January Fast - Guitar.mp3",
      "lesson6/56 Tennessee Waltz Fast - Guitar.mp3",
      "lesson7/52 Redwing Fast - Guitar.mp3",
      "lesson7/72 Bonaparte_s Retreat Fast - Guitar.mp3",
      "lesson8/60 Soldier_s Joy Fast - Guitar.mp3",
      "lesson8/65 Corrina Corrina Fast - Guitar.mp3",
      "lesson9/76 Marie Fast - Guitar.mp3",
      "lesson9/80 Golden Slippers Fast - Guitar.mp3",
      "lesson10/84 Liberty Fast - Guitar.mp3",
      "lesson10/88 Tears On My Pillow - Guitar.mp3",
      "lesson11/92 Wind That Shakes the Barley Fast - Guitar.mp3",
      "lesson11/96 East Texas Drag Fast - Guitar.mp3",
      "lesson12/99 Old Joe Clark Fast - Guitar.mp3",
      "lesson12/103 Pig Ankle Rag Fast - Guitar.mp3",
    ],
    [
      "lesson13/Dixie Hoedown - Slow Guitar.mp3",
      "lesson13/Streets Of Laredo - Slow Guitar.mp3",
      "lesson14/Turkey In The Straw - Slow Guitar.mp3",
      "lesson14/Florida Blues - Slow Guitar.mp3",
      "lesson15/01 My Shoes Keep Walking Back To You - Full Length - Guitar.mp3",
      "lesson15/05 My Shoes Keep Walking Back To You - Instrumental - Guitar.mp3",
      "lesson15/Texas Quick Step - Slow Guitar.mp3",
      "lesson16/Soppin the Gravy - Fast Guitar.mp3",
      "lesson16/Walk Along John - Fast Guitar.mp3",
      "lesson17/Red Fox Waltz - Fast Guitar.mp3",
      "lesson17/Missouri Waltz - Fast Guitar.mp3",
      "lesson18/I Don_t Love Nobody - Fast Guitar.mp3",
      "lesson18/Me & My Fiddle - Fast Guitar.mp3",
      "lesson19/The Plainsman - Guitar.mp3",
      "lesson19/31 A and E Waltz - Guitar.mp3",
      "lesson20/33 Sally Ann - Guitar.mp3",
      "lesson20/36 Lily Dale - Guitar.mp3",
      "lesson21/39 Speed the Plow - Guitar.mp3",
      "lesson21/41 Blackberry Rag - Guitar.mp3",
      "lesson22/45 Milk Cow Blues - Guitar.mp3",
      "lesson22/49 Home in San Antone - Guitar.mp3",
      "lesson23/50 Kentucky Waltz - Guitar.mp3",
      "lesson23/52 Rye Straw - Guitar.mp3",
      "lesson24/54 Red Wing Variation - Guitar.mp3",
      "lesson24/56 Black and White Rag - Guitar.mp3",
    ],
  ];

  let trackTitles = [
    [
      "Boil the Cabbage - Slow Fiddle & Guitar",
      "Oh! Susanna - Slow Fiddle & Guitar",
      "Liza Jane - Slow Guitar",
      "I Don't Love Nobody - Slow Guitar",
      "Cotton-Eyed Joe - Slow Guitar",
      "Goodbye Liza Jane - Slow Guitar",
      "The Girl I Left Behind Me - Slow Guitar",
      "Peek-A-Book Waltz - Slow Guitar",
      "Arkansas Traveller - Slow Guitar",
      "Red River Valley - Slow Guitar",
      "Eighth of January - Slow Guitar",
      "Tennessee Waltz - Slow Guitar",
      "Redwing - Slow Guitar",
      "Bonaparte's Retreat - Slow Guitar",
      "Soldier's Joy - Slow Guitar",
      "Corrina, Corrina - Slow Guitar",
      "Marie - Slow Guitar",
      "Golden Slippers - Slow Guitar",
      "Liberty - Slow Guitar",
      "Tears on My Pillow - Slow Guitar",
      "Wind That Shakes the Barley - Slow Guitar",
      "East Texas Drag - Slow Guitar",
      "Old Joe Clark - Slow Guitar",
      "Pig Ankle Rag - Slow Guitar",
    ],
    [
      "Boil the Cabbage - Fast Fiddle Guitar",
      "Oh! Susanna - Fast Guitar",
      "Liza Jane - Fast Guitar",
      "I Don't Love Nobody - Fast Guitar",
      "Cotton-Eyed Joe - Fast Guitar",
      "Goodbye Liza Jane - Fast Guitar",
      "The Girl I Left Behind Me - Fast Guitar",
      "Peek-A-Book Waltz - Fast Guitar",
      "Arkansas Traveller - Fast Guitar",
      "Red River Valley - Fast Guitar",
      "Eighth of January - Fast Guitar",
      "Tennessee Waltz - Fast Guitar",
      "Redwing - Fast Guitar",
      "Bonaparte's Retreat - Fast Guitar",
      "Soldier's Joy - Fast Guitar",
      "Corrina, Corrina - Fast Guitar",
      "Marie - Fast Guitar",
      "Golden Slippers - Fast Guitar",
      "Liberty - Fast Guitar",
      "Tears on My Pillow - Fast Guitar",
      "Wind That Shakes the Barley - Fast Guitar",
      "East Texas Drag - Fast Guitar",
      "Old Joe Clark - Fast Guitar",
      "Pig Ankle Rag - Fast Guitar",
    ],
    [
      "Dixie Hoedown - Guitar",
      "Streets of Laredo - Guitar",
      "Turkey in the Straw - Guitar",
      "Florida Blues - Guitar",
      "My Shoes Keep Walking Back to You - Full Length Guitar",
      "My Shoes Keep Walking Back to You - Instrumental Guitar",
      "Texas Quick Step - Guitar",
      "Soppin' the Gravy - Guitar",
      "Walk Along John - Guitar",
      "Red Fox Waltz - Fast Guitar",
      "Missouri Waltz - Guitar",
      "I Don't Love Nobody - Guitar",
      "Me and My Fiddle - Guitar",
      "The Plainsman - Guitar",
      "A & E Waltz - Guitar",
      "Sally Ann - Guitar",
      "Lily Dale - Guitar",
      "Lily Dale - Guitar",
      "Speed the Plow - Guitar",
      "Blackberry Rag - Guitar",
      "Milk Cow Blues - Guitar",
      "Home in San Antone - Guitar",
      "Kentucky Waltz - Guitar",
      "Rye Straw - Guitar",
      "Red Wing - Guitar",
      "Black & White Rag - Guitar",
    ],
  ];

  let slowTracksTitles = [
    "Boil the Cabbage - Slow Fiddle & Guitar",
    "Oh! Susanna - Slow Fiddle & Guitar",
    "Liza Jane - Slow Guitar",
    "I Don't Love Nobody - Slow Guitar",
    "Cotton-Eyed Joe - Slow Guitar",
    "Goodbye Liza Jane - Slow Guitar",
    "The Girl I Left Behind Me - Slow Guitar",
    "Peek-A-Book Waltz - Slow Guitar",
    "Arkansas Traveller - Slow Guitar",
    "Red River Valley - Slow Guitar",
    "Eighth of January - Slow Guitar",
    "Tennessee Waltz - Slow Guitar",
    "Redwing - Slow Guitar",
    "Bonaparte's Retreat - Slow Guitar",
    "Soldier's Joy - Slow Guitar",
    "Corrina, Corrina - Slow Guitar",
    "Marie - Slow Guitar",
    "Golden Slippers - Slow Guitar",
    "Liberty - Slow Guitar",
    "Tears on My Pillow - Slow Guitar",
    "Wind That Shakes the Barley - Slow Guitar",
    "East Texas Drag - Slow Guitar",
    "Old Joe Clark - Slow Guitar",
    "Pig Ankle Rag - Slow Guitar",
  ];

  let fastGuitarSessionOne = [
    "Boil the Cabbage - Fast Fiddle Guitar",
    "Oh! Susanna - Fast Guitar",
    "Liza Jane - Fast Guitar",
    "I Don't Love Nobody - Fast Guitar",
    "Cotton-Eyed Joe - Fast Guitar",
    "Goodbye Liza Jane - Fast Guitar",
    "The Girl I Left Behind Me - Fast Guitar",
    "Peek-A-Book Waltz - Fast Guitar",
    "Arkansas Traveller - Fast Guitar",
    "Red River Valley - Fast Guitar",
    "Eighth of January - Fast Guitar",
    "Tennessee Waltz - Fast Guitar",
    "Redwing - Fast Guitar",
    "Bonaparte's Retreat - Fast Guitar",
    "Soldier's Joy - Fast Guitar",
    "Corrina, Corrina - Fast Guitar",
    "Marie - Fast Guitar",
    "Golden Slippers - Fast Guitar",
    "Liberty - Fast Guitar",
    "Tears on My Pillow - Fast Guitar",
    "Wind That Shakes the Barley - Fast Guitar",
    "East Texas Drag - Fast Guitar",
    "Old Joe Clark - Fast Guitar",
    "Pig Ankle Rag - Fast Guitar",
  ];

  let fastGuitarSessionTwo = [
    "Dixie Hoedown - Guitar",
    "Streets of Laredo - Guitar",
    "Turkey in the Straw - Guitar",
    "Florida Blues - Guitar",
    "My Shoes Keep Walking Back to You - Full Length Guitar",
    "My Shoes Keep Walking Back to You - Instrumental Guitar",
    "Texas Quick Step - Guitar",
    "Soppin' the Gravy - Guitar",
    "Walk Along John - Guitar",
    "Red Fox Waltz - Fast Guitar",
    "Missouri Waltz - Guitar",
    "I Don't Love Nobody - Guitar",
    "Me and My Fiddle - Guitar",
    "The Plainsman - Guitar",
    "A & E Waltz - Guitar",
    "Sally Ann - Guitar",
    "Lily Dale - Guitar",
    "Lily Dale - Guitar",
    "Speed the Plow - Guitar",
    "Blackberry Rag - Guitar",
    "Milk Cow Blues - Guitar",
    "Home in San Antone - Guitar",
    "Kentucky Waltz - Guitar",
    "Rye Straw - Guitar",
    "Red Wing - Guitar",
    "Black & White Rag - Guitar",
  ];

  let fastGuitarSessionThree = [
    "Clarinet Polka - Guitar",
    "Paddy on the Turnpike - Guitar",
    "Moonlight on the River Colorado - Guitar",
    "Shenandoah - Guitar",
    "Ragtime Annie - Guitar",
    "Bill Cheatum - Guitar",
    "La Golondrina - Guitar",
    "La Golondrina - Guitar",
    "Morningstar Waltz - Fiddle and Guitar",
    "Tears on My Pillow - C - Guitar",
    "Maiden's Prayer - Guitar",
    "Ostinelli's Reel - Guitar",
    "Dill Pickle Rag - Guitar",
    "Boot Heel Drag - Guitar",
    "Boot Heel Drag - Guitar",
    "Corrina, Corrina - Guitar",
    "Sopris Waltz - (Short Version) Guitar",
    "Sopris Waltz - (Long Version) Guitar",
    "Jobob Rag - Guitar",
    "Jesse Polka - Guitar",
    "Jesse Polka - Guitar",
    "Cripple Creek - Guitar",
    "Back Up and Push - Guitar",
    "Nervous Breakdown - Guitar",
    "Arkandas Traveler - Variation - Guitar",
    "Florida Blues - Variation - Guitar",
    "Tennessee Waltz - Variation - Guitar",
    "Cotton Eyed Joe - Variation - Guitar",
    "Paddy in the Turnpike - Long Guitar",
    "Bill Cheatum - Long Guitar",
  ];

  let tunesSessionOne = [
    "Boil the Cabbage - Fast Fiddle and Guitar",
    "Oh! Susanna - Fast Fiddle and Guitar",
    "Liza Jane - Fast Fiddle and Guitar",
    "I Don't Love Nobody - Fast Fiddle and Guitar",
    "Cotton-Eyed Joe - Fast Fiddle and Guitar",
    "Goodbye Liza Jane - Fast Fiddle and Guitar",
    "The Girl I Left Behind Me - Fast Fiddle and Guitar",
    "Peek-A-Book Waltz - Fast Fiddle and Guitar",
    "Arkansas Traveller - Fast Fiddle and Guitar",
    "Red River Valley - Fast Fiddle and Guitar",
    "Red River Valley - Fast Harmony Fiddle and Guitar",
    "Red River Valley - Fast Twin Fiddle and Guitar",
    "Eighth of January - Fast Fiddle and Guitar",
    "Tennessee Waltz - Fast Fiddle and Guitar",
    "Red Wing - Fast Fiddle and Guitar",
    "Bonaparte's Retreat - Fast Fiddle and Guitar",
    "Soldier's Joy - Fast Fiddle and Guitar",
    "Corrina, Corrina - Fast Fiddle and Guitar",
    "Corrina, Corrina - Fast Harmony Fiddle and Guitar",
    "Corrina, Corrina - Fast Twin Fiddle and Guitar",
    "Marie - Fast Fiddle and Guitar",
    "Golden Slippers - Fast Fiddle and Guitar",
    "Liberty - Fast Fiddle and Guitar",
    "Liberty - Fast Harmony Fiddle and Guitar",
    "Liberty - Fast Twin Fiddle and Guitar",
    "Tears on My Pillow - Fast Fiddle and Guitar",
    "Wind That Shakes the Barley - Fast Fiddle and Guitar",
    "East Texas Drag - Fast Fiddle and Guitar",
    "Old Joe Clark - Fast Fiddle and Guitar",
    "Pig Ankle Rag - Fast Fiddle and Guitar",
    "Pig Ankle Rag - Fast Twin Fiddle and Guitar",
  ];

  let tunesSessionTwo = [
    "Dixie Hoedown - Guitar and Fiddle",
    "Streets of Laredo - Guitar and Fiddle",
    "Turkey in the Straw - Guitar and Fiddle",
    "Florida Blues - Guitar and Fiddle",
    "My Shoes Keep Walking Back to You - Full Length Twin Fiddle",
    "My Shoes Keep Walking Back to You - Instrumental Twin Fiddle",
    "Texas Quick Step - Guitar and Fiddle",
    "Soppin' the Gravy - Guitar and Fiddle",
    "Walk Along John - Guitar and Fiddle",
    "Red Fox Waltz - Fast Guitar and Fiddle 1",
    "Red Fox Waltz - Fast Guitar and All Fiddles",
    "Red Fox Waltz - Fast Guitar and Fiddles 1 & 2",
    "Red Fox Waltz - Fast Guitar and Fiddles 1 & 3",
    "Missouri Waltz - Guitar and Fiddle",
    "I Don't Love Nobody - Guitar and Fiddle",
    "Me and My Fiddle - Guitar and Fiddle",
    "The Plainsman - Guitar and Fiddle",
    "A & E Waltz - Guitar and Fiddle",
    "Sally Ann - Guitar and Fiddle",
    "Lily Dale - Guitar and Fiddle Melody",
    "Lily Dale - Guitar and All Fiddles",
    "Speed the Plow - Guitar and Fiddle",
    "Blackberry Rag - Guitar and Fiddle",
    "Milk Cow Blues - Guitar & Fiddles",
    "Milk Cow Blues - Guitar & Fiddle Melody",
    "Home in San Antone - Guitar & Fiddles",
    "Home in San Antone - Guitar & Fiddle Melody",
    "Kentucky Waltz - Guitar and Fiddle",
    "Rye Straw - Guitar and Fiddle",
    "Red Wing - Guitar and Fiddle",
    "Black & White Rag - Guitar and Fiddle",
  ];

  let tunesSessionThree = [
    "Clarinet Polka - Guitar and Fiddle",
    "Paddy on the Turnpike - Fiddle and Guitar",
    "Moonlight on the River Colorado - Fiddle and Guitar",
    "Shenandoah - Fiddle and Guitar",
    "Ragtime Annie - Fast Fiddle and Guitar",
    "Bill Cheatum - Fiddle and Guitar",
    "La Golondrina - Fiddle and Guitar",
    "La Golondrina - Twin Fiddle and Guitar",
    "Morningstar Waltz - Fiddle and Guitar",
    "Tears on My Pillow - C - Fiddle and Guitar",
    "Maiden's Prayer - Fiddle and Guitar",
    "Ostinelli's Reel - Fiddle and Guitar",
    "Dill Pickle Rag - Fiddle and Guitar",
    "Boot Heel Drag - Guitar and Fiddle 1 and 2",
    "Boot Heel Drag - Fiddle and Guitar",
    "Corrina, Corrina - E Fiddle and Guitar",
    "Sopris Waltz - (Short Version) Fiddle and Guitar",
    "Sopris Waltz - (Long Version) Fiddle and Guitar",
    "Jobob Rag - Fiddle and Guitar",
    "Jesse Polka - Fast Melody Fiddle and Guitar",
    "Jesse Polka - Fast Twin Fiddles and Guitar",
    "Cripple Creek - Fast Fiddle and Guitar",
    "Back Up and Push - Fast Melody Fiddle and Guitar",
    "Back Up and Push - Fast Twin Fiddle and Guitar",
    "Nervous Breakdown - Fast Fiddle and Guitar",
    "Nervous Breakdown - (Alternate Ending) Fast Fiddle and Guitar",
    "Arkandas Traveler - Variation - Fast Fiddle and Guitar",
    "Florida Blues - Variation - Fast Fiddle and Guitar",
    "Tennessee Waltz - Variation Fast Fiddle and Guitar",
    "Cotton Eyed Joe - Variation Fast Fiddle and Guitar",
    "Paddy in the Turnpike - Long Fast Fiddle and Guitar",
    "Bill Cheatum - Long Fast Fiddle and Guitar",
  ];

  const navigate = useNavigate();

  return (
    <>
      <RadioSidebar
        stations={trackTypes}
        selectedStation={trackType}
        onSelectStation={(station) => {
          setTrackType(station);
        }}
        setSelectedTrack={setSelectedTrack}
      />
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          backgroundColor: "#f8f6f1",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        className="main-container"
      >
        <div className="navbar" style={{ position: "sticky" }}>
          <div
            style={{
              marginLeft: 85,
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <img src={HeaderImage} style={{ width: 180, height: 40 }} /> */}
          </div>
          <div
            onClick={() => {
              navigate("/profile");
            }}
            className="nav-item"
          >
            Home
          </div>
          <div
            onClick={() => {
              navigate("/home");
            }}
            className="nav-item"
          >
            Videos
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            onClick={() => {
              navigate("/radio");
            }}
            className="nav-item-selected"
          >
            Radio
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            // onClick={() => {
            //   navigate("/profile");
            // }}
            className="nav-item"
            style={{ marginRight: 220 }}
            onClick={() => {
              alert(
                "Once we transfer the domain, we can iframe circle communnity in to the page"
              );
            }}
          >
            Community
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
        </div>
        {/* <div
          id="sidebar"
          style={{
            backgroundColor: "#f5f5f5",
            borderRight: "2px solid #ccc",
          }}
        >
          <img src={HeaderImage} style={{ width: 300 }} />
          <div style={{ flexDirection: "row", display: "flex", height: 10 }}>
            {/* <form id="search-form" role="search" style={{ marginRight: 0 }}>
              <input
                id="q"
                aria-label="Search contacts"
                placeholder="Search"
                type="search"
                name="q"
              />
              <div id="search-spinner" aria-hidden hidden={true} />
              <div className="sr-only" aria-live="polite"></div>
            </form> 
            <div
              onClick={() => {
                toggleSidebar();
              }}
              className="close-toggle"
              style={{
                position: "absolute",
                right: 20,
                // border: "1px solid black",
                width: 22,
                height: 22,
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                fontWeight: "bold",
              }}
            >
              x
            </div>
          </div>
          <nav style={{ cursor: "pointer" }}>
            <div
              style={{
                fontWeight: "bold",
                textAlign: "left",
                fontSize: 32,
                marginBottom: 8,
              }}
            >
              Radio Stations
            </div>

            {trackTypes.map((item, index) => {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: 16,
                  }}
                  onClick={() => {
                    setTrackType(index);
                  }}
                  className={`station-item ${
                    trackType === index ? "selected" : ""
                  }`}
                >
                  {item}
                </div>
              );
            })}
          </nav>
        </div> */}
        <div
          style={{ padding: 10, width: "100vw", marginTop: 0 }}
          className="fade-in"
        >
          <div
            style={{
              marginTop: 0,
              width: "100vw",
              flexfDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ width: "50vw", paddingTop: 25, maxWidth: 715 }}>
              <div style={{ textAlign: "left", fontSize: 14 }}>Playlist</div>
              <div
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: "2rem",
                  fontWeight: "bold",
                }}
              >
                {trackTypes[trackType]}
              </div>
              <div style={{ textAlign: "left" }}>
                {trackTitles[trackType].length} songs
              </div>
            </div>
          </div>

          <div
            style={{
              maxHeight: "62vh",
              overflow: "scroll",
              marginTop: 30,
              width: "100vw",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
            }}
          >
            {trackTitles[trackType].map((item, index) => {
              return (
                <div>
                  <div
                    style={{
                      fontWeight: selectedTrack == index ? "bold" : "normal",
                      fontSize: 16,
                      textAlign: "left",
                      cursor: "pointer",
                      // marginLeft: "12vh",
                      height: 60,
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#efebe1",
                      width: "50vw",
                      maxWidth: 715,
                    }}
                    onClick={() => {
                      setSelectedTrack(index);
                    }}
                    className={`Button track-list-item ${
                      selectedTrack == index ? "selected" : ""
                    }`}
                  >
                    <strong>{index + 1}</strong>&nbsp;&nbsp;&nbsp;{item}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            marginTop: 30,
            height: 100,
            backgroundColor: "#efebe1",
            borderRadius: 2,
            position: "absolute",
            bottom: 0,
            width: "100vw",
            paddingLeft: "10vw",
          }}
        > */}

        {/* </div> */}
        <ControlBar
          tuneFile={tuneFiles[trackType][selectedTrack]}
          trackTitle={trackTitles[trackType][selectedTrack]}
          trackGenre={trackTypes[trackType]}
          isPlaying={isPlaying}
          onPlayPause={togglePlayPause}
          onNext={nextTrack}
          onPrevious={previousTrack}
          onShuffle={shuffleTracks}
          onReplay={replayTrack}
          setIsPlaying={setIsPlaying}
        />
      </div>
    </>
  );
}
